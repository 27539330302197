// 
// topbar.scss
//

.navbar-custom {
  padding: 0 $grid-gutter-width;
  background-color: $anatomy-dark-light;
  box-shadow: $shadow;
  min-height: $topbar-height;
  position: fixed;
  left: $leftbar-width;
  top: 0;
  right: 0;
  z-index: 1001;

  .topbar-left {
    height: $topbar-height;
    position: fixed;
    z-index: 1;
    width: $leftbar-width;
    text-align: center;
    top: 0;
    left: 0;

    .logo {
      line-height: $topbar-height;

      i {
        display: none;
      }
    }
  }

  .topbar-right-menu {
    position: relative;
    z-index: 1;

    li {
      float: left;
      max-height: $topbar-height;

      &.show {
        .nav-link {
          color: $gray-700;
        }
      }
    }

    .nav-link {
      padding: 0;
      color: $gray-600;
      min-width: 32px;
      display: block;
      text-align: center;
      margin: 0 10px;
      position: relative;
    }
  }
}

.topbar-dropdown {
  .nav-link {
    line-height: $topbar-height;
  }
}

.notification-list {
  margin-left: 0;

  &.big-mode {
    display: flex;
    flex-direction: column;
    text-align: center;
    transition: all .5s ease;

    &.active {
      a {
        color: $primary;
        background-color: $anatomy-dark-extra-light;
      }

      span {
        color: $white;
      }
    }

    a {
      color: $white;
      transition: all .5s ease;
      height: $topbar-height;
      padding: 3px 15px;

      .menu-icon {
        display: block;
        height: 35px;
        font-size: 30px;
      }

      span {
        display: block;
        font-size: 12px;
        margin-top: 5px;
      }

      &:hover {
        color: $primary;
        cursor: pointer;
      }
    }
  }

  .noti-title {
    background-color: transparent;
    padding: 15px 20px;
  }

  .noti-icon {
    font-size: 22px;
    vertical-align: middle;
    line-height: $topbar-height;
  }

  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 22px;
    right: 6px;
    border-radius: 50%;
    height: 7px;
    width: 7px;
    background-color: $danger;
  }

  .notify-item {
    padding: 10px 20px;

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: $white;
    }

    .notify-details {
      margin-bottom: 0;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;

      b {
        font-weight: 500;
      }

      small {
        display: block;
      }

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }

    .user-msg {
      margin-left: 45px;
      white-space: normal;
      line-height: 16px;
    }
  }

  .topbar-dropdown-menu {
    .notify-item {
      padding: 7px 20px;
    }
  }
}

.profile-dropdown {
  min-width: 170px;

  i, span {
    vertical-align: middle;
  }
}


.topnav-navbar-dark {
  .nav-username {
    padding-left: 10px !important;
    padding-right: 10px !important;
    background-color: $anatomy-dark-extra-light;
    border: 1px solid $anatomy-dark-extra-light;
    min-height: $topbar-height;
    margin-left: 10px;

    .username {
      color: $white;
    }
  }
}

.nav-user {
  padding: calc(#{$topbar-height - 39px} / 2) 20px calc(#{$topbar-height - 39px} / 2) 57px !important;
  text-align: left !important;
  position: relative;
  background-color: lighten($gray-100, 2.5%);
  border-width: 0 1px;
  min-height: $topbar-height;

  .account-user-avatar {
    position: absolute;
    top: calc(#{$topbar-height - 32px} / 2);
    left: 15px;

    img {
      height: 32px;
      width: 32px;
    }
  }

  .account-position {
    display: block;
    font-size: 12px;
    margin-top: -3px;
  }

  .account-user-name {
    display: block;
    font-weight: $font-weight-semibold;

    &.one-line {
      line-height: 36px;
    }
  }
}

[data-keep-enlarged="true"] {
  .navbar-custom {
    padding-left: 0;
  }

  .button-menu-mobile {
    &.disable-btn {
      display: inline-block;
    }
  }
}

@media (max-width: 600px) {
  .navbar-custom {
    .topbar-right-menu {
      position: initial;
    }

    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .navbar-custom {
    left: 70px;
    right: 0;
  }
}

@include media-breakpoint-down(sm) {
  .navbar-custom {
    left: 0;
    padding: 0;
  }
  .button-menu-mobile {
    &.disable-btn {
      display: inline-block;
    }
  }
  .nav-user {
    padding: 17px 5px 17px 57px !important;

    .account-position,
    .account-user-name {
      display: none;
    }
  }
}

@media (max-width: 360px) {
  .navbar-custom {
    .topbar-right-menu {
      .nav-link {
        margin: 0 6px;
      }
    }

    &.topnav-navbar {
      .container-fluid {
        padding-right: 12px;
        padding-left: 12px;
      }

      .navbar-toggle {
        margin: 27px 3px
      }

      .button-menu-mobile {
        width: auto;
      }
    }
  }
}

// Scrollable Layout

@include media-breakpoint-up(xl) {
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) {
    .navbar-custom {
      position: absolute;
    }
  }
}

@media screen and (max-width: 295px) {
  .topnav-logo {
    display: none;
  }
}

@include media-breakpoint-down(xs) {
  .nav-username {
    background-color: transparent !important;
    border: none !important;
    margin: 0 !important;

    span {
      display: none;
    }
  }

  .notification-list {
    &.big-mode {
      &.active {
        a {
          color: $primary;
          background-color: transparent;
        }
      }

      a {
        padding: 10px 10px;

        .menu-icon {
          display: block;
          height: 35px;
          font-size: 30px;
        }

        span {
          display: none;
        }
      }
    }
  }
}

body[data-layout-mode="boxed"] {
  .navbar-custom {
    position: relative;
    left: 0 !important;
    margin: -$topbar-height (-$grid-gutter-width / 2) 0;
  }

  &[data-layout="topnav"] {
    .navbar-custom {
      margin: 0;
    }
  }
}