// 
// social.scss
//

.social-network-item {
  height: 3rem;
  width: 3rem;
  line-height: calc(3rem - 4px);
  display: block;
  border: 2px solid #adb5bd;
  border-radius: 50%;
  color: #adb5bd;

  &.facebook {
    border-color: #2c88ff !important;

    i {
      color: #2c88ff !important;
    }
  }

  &.google {
    border-color: #ea4435 !important;

    i {
      color: #ea4435 !important;
    }
  }
}

.btn-img {
  img {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 5px;
  }
}