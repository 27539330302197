//
// authentication.scss
//

// authentication pages background
body.authentication-bg {
  background-image: $auth-bg-pattern-img;
  background-size: cover;
  background-position: center;
}

body.auth {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $input-bg inset !important;
    -webkit-text-fill-color: $text-color;
    border-radius: $input-border-radius;
    border-color: $input-border-color
  }

  .secure-code-inputs {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      padding: 0;
      text-align: center;
      width: calc(2.2125rem + 2px);;
      height: calc(2.2125rem + 2px);;
      margin-left: 2px;
      margin-right: 2px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }

  .btn-login {
    padding-left: 25px;
    padding-right: 25px;
  }

  .btn-social {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0;

    &:hover {
      background: none;
    }

    &.facebook {
      color: #1877F2;
      border-color: #1877F2;

      img {
        height: 50%;
        width: 50%;
      }
    }

    &.google {
      color: #1877F2;
      border-color: #1877F2;

      img {
        height: 40%;
        width: 40%;
      }
    }

    &.apple {
      color: #ffffff;
      border-color: #ffffff;

      img {
        height: 50%;
        width: 50%;
      }
    }
  }
}

.authentication-bg.enlarged,
.auth-fluid-pages.enlarged,
body.auth-fluid-pages[data-leftbar-compact-mode=condensed] {
  min-height: 100px;
}

// Logout page
.logout-icon {
  width: 140px;
}

// Authentication fluid
.auth-fluid {
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  align-items: stretch;
  background: url("../images/bg-wallet.jpg") center;
  background-size: cover;

  .auth-fluid-form-box {
    max-width: 480px;
    border-radius: 0;
    z-index: 2;
    padding: 3rem 2rem;
    background-color: $body-bg;
    position: relative;
    width: 100%;
  }

  .auth-fluid-right {
    padding: 6rem 3rem;
    flex: 1;
    position: relative;
    color: $white;
    background-color: rgba($black, 0.3);
  }
}

.auth-brand {
  margin-bottom: 2rem;
}

.auth-user-testimonial {
  position: absolute;
  margin: 0 auto;
  padding: 0 1.75rem;
  bottom: 3rem;
  left: 0;
  right: 0;

  p.lead {
    font-size: 1.125rem;
    margin: 0 auto 20px auto;
    max-width: 700px;
  }
}

@include media-breakpoint-up(lg) {
  .auth-brand {
    position: absolute;
    left: 0;
    top: 3rem;
    width: 100%;
  }
}

@include media-breakpoint-down(md) {
  .auth-fluid {
    display: block;

    .auth-fluid-form-box {
      max-width: 100%;
      min-height: 100vh
    }

    .auth-fluid-right {
      display: none;
    }
  }
}