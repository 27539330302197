// 
// page-head.scss
//

.page-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;

  @include media-breakpoint-down(sm) {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  &.with-left-menu {
    padding-top: 30px;
    padding-bottom: 15px;

    .page-title {
      margin: 0;
      padding: 0;
    }
  }

  .page-title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $page-title-color;
  }

  .page-title-right {
    float: right;
  }

  .breadcrumb {
    padding-top: 8px;
  }
}

.page-title-box-sm {
  .page-title {
    line-height: 1 !important;
    margin-bottom: 25px;
  }

  .page-title-right {
    float: right;
    margin-top: 0;
  }

  .breadcrumb {
    padding-top: 0;
    margin-top: -3px !important;
  }
}


.text-title {
  color: $text-title-color;

  &:hover {
    color: $text-title-color;
  }
}

@include media-breakpoint-down(sm) {
  .page-title-box {
    .page-title {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .breadcrumb {
      display: none;
    }

    .page-title-right {
      display: none;
    }
  }
}

@media (max-width: 419px) {
  .page-title-box .breadcrumb {
    display: none;
  }
}