// Wrapper
.wrapper {
  margin-top: $topbar-height;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

//Content Page
.content-page {
  margin-left: 0 !important;
  padding: 0 21px;
  overflow: hidden;
}

textarea {
  &.no-resize {
    resize: none;
  }
}

.navbar-custom {
  &.topnav-navbar {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
  }
}

.modal-open .container-fluid, .modal-open .container {
  filter: blur(1.0rem);
}

.modal-open .topnav-navbar {
  display: none;
}

.topnav-navbar-dark {
  .nav-link {
    color: $text-color !important;

    &.menu-item:hover {
      color: $primary !important;
      transition: all .5s ease;
    }

    &.nav-username:hover {
      background-color: $primary !important;
      transition: all .5s ease;
    }
  }
}

.bg-anatomy-gradient {
  background: $anatomy-gradient;
}

.bg-anatomy-gray {
  background-color: $anatomy-gray;
}

// Display for themes property
.display-dark {
  display: none;
}

.display-light {
  display: none;
}

body[data-leftbar-theme="dark"] {
  .display-dark {
    display: block;
  }

  .display-light {
    display: none;
  }
}

body {
  .display-dark {
    display: none;
  }

  .display-light {
    display: block;
  }
}

.wallets {
  overflow-y: hidden;
  overflow-x: scroll;
  margin-top: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  .wallet-item {
    height: 150px;
    margin: 10px 20px 10px 10px;
    cursor: pointer;
    transition: all .25s ease-in-out;

    &:hover {
      transform: scale(1.02);
    }

    .card {
      border-radius: 30px;
    }

    img {
      max-height: 140px;
    }

    &.drag {
      .card {
        transform: scale(1.02);
      }
    }

    .card {
      background-color: $anatomy-dark-light;
      min-width: 350px;
      width: calc(100vw / 4);
      height: 100%;
    }
  }
}

#wallet-edit-button, #expense-edit-button {
  &.active {
    opacity: 2.0;
  }
}

.expenses {
  display: grid;
  grid-template-columns: repeat(auto-fill, 125px);
  grid-gap: 10px;
  justify-content: space-between;

  .expense-item {
    margin: 10px 15px;
    transition: all .25s ease-in-out;

    &:hover {
      transform: scale(1.04);
    }
  }
}

.expense-circle {
  height: 3.5rem;
  width: 3.5rem;
  cursor: pointer;

  &.sm {
    height: 2.5rem;
    width: 2.5rem;

    .expense-icon {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .expense-icon {
    pointer-events: none;
    height: 2.0rem;
    width: 2.0rem;
    filter: brightness(0) invert(1);
  }

  &.drag {
    span {
      transition: all 0.3s ease-out;
      background: $expense-drag-color;
    }
  }

  span {
    align-items: center;
    color: $white;
    display: flex;
    font-weight: $font-weight-semibold;
    height: 100%;
    justify-content: center;
    width: 100%;
    border-radius: 50% !important;
  }
}

.shake {
  animation-name: shake;
  animation-duration: 1.25s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-2px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -1px) rotate(-1deg);
  }
}

.sticky-container {
  background-color: $body-bg;
  position: sticky;
  top: 70px;
  z-index: 1002;

  &.no-padding-bottom {
    .content-page {
      padding-bottom: 0 !important;
    }
  }

  .content-page {
    min-height: auto;
    padding-bottom: 0 !important;
  }
}

.page-title-right {
  .form-group {
    min-width: 200px;
  }
}

.history {
  overflow-y: scroll;

  .income-item {
    .volume {
      color: $success;
    }
  }

  .outcome-item {
    .volume {
      color: $danger;
    }
  }
}

@include media-breakpoint-down(md) {
  .statistic, .history {
    max-height: none !important;
  }
}

.anatomy-modal {
  .modal-content {
    background-color: transparent;
  }

  .modal-body {
    background-color: $card-bg;
    border-radius: 25px;
  }

  .modal-header {
    margin-right: 0;
    margin-bottom: 5px;
    border: none;
  }

  .close {
    background-color: $card-bg;
    opacity: 1.0;
    border-radius: 50%;
    padding: 5px;
    width: 30px;
    height: 30px;
    margin-left: auto;
  }
}

.hidden {
  display: none;
}

.table-first-row-border-top-0 tr:first-child > td {
  border-top: 0;
}

input {
  &.no-arrows {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}

.manage-wallet-img {
  width: 1.15rem;
}

.no-box-shadow {
  box-shadow: none !important;
}

.py-1-rem {
  padding-left: 1rem;
  padding-right: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

.transactions {
  h1, h2, h3, h4, h5, h6 {
    &:first-child {
      margin-top: 0;
    }
  }

  .transaction-row {
    cursor: pointer;
    transition: all .25s ease-in-out;

    &:hover {
      transform: scale(1.01);
    }
  }
}

.wallet-details-buttons {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.profile-limits-row {
  .card {
    min-height: 130px;
    height: 130px;

    .text-uppercase {
      min-height: 30px
    }
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

#animation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  #animation-content {
    width: 200px;
    height: 200px;
  }

  .animation-text {
    margin: 0;
    padding: 0;
  }
}