// 
// menu.scss
//

// Logo
.logo {
  display: block;
  line-height: $topbar-height;
  width: $leftbar-width;
  position: fixed;
  top: 0;

  span.logo-lg {
    display: block;
  }

  span.logo-sm {
    display: none;
  }
}

// Wrapper
.wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

//Content Page
.content-page {
  margin-left: $leftbar-width;
  overflow: hidden;
  padding: $topbar-height 12px 65px;
}

// Left Side-menu (Default dark)
.left-side-menu {
  width: $leftbar-width;
  z-index: 10;
  background: $bg-leftbar;
  bottom: 0;
  position: fixed;
  transition: all .2s ease-out;
  top: 0;
  padding-top: $topbar-height;
  margin-bottom: 60px;
  box-shadow: $shadow;

  .left-side-filter {
    padding: 15px;
  }
}

@include media-breakpoint-down(md) {
  .left-side-menu {
    margin-bottom: 0;
  }
}

.button-menu-mobile {
  display: none;
  border: none;
  color: $anatomy-white;
  width: 60px;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  float: left;

  i {
    vertical-align: middle;
  }

  &.disable-btn {
    display: none;
  }
}

// Side-nav
.side-nav {
  .side-nav-link {
    color: $menu-item;
    display: block;
    padding: 10px 30px;
    font-size: 0.9375rem;
    position: relative;
    transition: all 0.4s;

    &:hover,
    &:focus,
    &:active {
      color: $menu-item-hover;
      text-decoration: none;
    }

    span {
      vertical-align: middle;
    }

    i {
      display: inline-block;
      line-height: 1.0625rem;
      margin: 0 10px 0 0;
      font-size: 1.1rem;
      vertical-align: middle;
      width: 20px;
    }
  }

  .menu-arrow {
    transition: transform .15s;
    position: absolute;
    right: 30px;
    display: inline-block;
    font-family: 'Material Design Icons', serif;
    text-rendering: auto;
    line-height: 1.5rem;
    font-size: 1.1rem;
    transform: translate(0, 0);

    &:before {
      content: "\F142";
    }
  }

  .badge {
    margin-top: 3px;
  }

  .side-nav-item {
    &.mm-active {
      > a {
        .menu-arrow {
          transform: rotate(90deg);
        }
      }
    }
  }

  .side-nav-title {
    padding: 12px 30px;
    letter-spacing: .05em;
    pointer-events: none;
    cursor: default;
    font-size: 0.6875rem;
    text-transform: uppercase;
    color: $menu-item;
    font-weight: $font-weight-bold;
  }

  .mm-active {
    > a {
      color: $menu-item-active !important;
    }
  }
}

@include media-breakpoint-down(sm) {
  body {
    overflow-x: hidden;
  }

  .button-menu-mobile {
    display: block;
  }

  .left-side-menu {
    box-shadow: $shadow;
    display: none;
    z-index: 10 !important;
  }
  .sidebar-enable {
    .left-side-menu {
      display: block;
    }
  }
  .navbar-nav.navbar-right {
    float: right;
  }
  .content-page {
    margin-left: 0 !important;
    padding: 65px 10px 65px;
  }
}

/// Help-box
.help-box {
  border-radius: 5px;
  padding: 20px;
  margin: 65px 25px 25px;
  position: relative;
  background-color: $help-box-light-bg;

  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

// Light
body[data-leftbar-theme="light"] {
  .help-box {
    background-color: $primary;
  }
}

// Layout -topnav
body[data-layout="topnav"] {
  .content-page {
    margin-left: 0 !important;
    padding: 0 0 60px 0;
  }
}

// Layout-boxed
body[data-layout-mode="boxed"] {
  background-color: $boxed-layout-bg;

  .wrapper {
    max-width: $boxed-layout-width;
    margin: 0 auto;
    background-color: $body-bg;
    box-shadow: $shadow;
  }
}

// Scrollable Layout

@include media-breakpoint-up(xl) {
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) {
    padding-bottom: 0;

    .wrapper {
      display: flex;
    }

    .left-side-menu {
      position: relative;
      min-width: $leftbar-width;
      max-width: $leftbar-width;
      padding-top: 0;
    }

    .logo {
      position: relative;
      margin-top: 0;
    }

    .content-page {
      margin-left: 0;
      width: 100%;
      padding-bottom: 60px;
    }
  }
}

// Detached Left sidebar

body[data-layout="detached"] {
  padding-bottom: 0;

  @include media-breakpoint-up(lg) {
    .container-fluid {
      max-width: 95%;
    }
  }

  &[data-layout-mode="boxed"] {
    .wrapper {
      max-width: 100%;
    }
  }

  &[data-leftbar-compact-mode="scrollable"] {
    .wrapper {
      padding-top: 70px;
    }
  }

  .wrapper {
    display: flex;
    overflow: inherit;
  }

  .content-page {
    margin-left: 0;
    overflow: hidden;
    position: relative;
    margin-right: -15px;
    width: 100%;
    padding-bottom: 60px;
  }

  .left-side-menu {
    position: relative;
    background: $bg-detached-leftbar !important;
    min-width: $leftbar-width;
    max-width: $leftbar-width;
    box-shadow: $shadow;
    margin-top: 30px;
    padding-top: 0 !important;
    z-index: 1001 !important;

    .side-nav {
      .side-nav-link {
        color: $menu-item-dark !important;

        &:hover,
        &:focus,
        &:active {
          color: $menu-item-dark-hover !important;
        }
      }

      .mm-active {
        > a {
          color: $menu-item-dark-active !important;
        }
      }

      .side-nav-title {
        color: $menu-item-dark;
      }
    }
  }

  .leftbar-icon {
    display: inline-block;
    line-height: 1.0625rem;
    margin: 0 10px 0 0;
    font-size: 1.1rem;
    vertical-align: middle;
    width: 20px;
  }

  .leftbar-user {
    background: url("../images/waves.png") no-repeat;
    padding: 50px 20px 20px 20px;
    text-align: center;

    .leftbar-user-name {
      overflow: hidden;
      font-weight: 700;
      color: $text-color;
      margin-left: 12px;
      margin-top: 8px;
      display: block;
    }
  }

  @include media-breakpoint-down(sm) {
    &.sidebar-enable {
      .left-side-menu {
        position: fixed;
        left: 0;
        overflow-y: auto;
        margin-top: 70px;
      }
    }
    .leftbar-user {
      background: none;
      padding: 10px 20px 0 20px;
      text-align: center;
    }

    .wrapper {
      max-width: 100%;
    }
    .content-page {
      margin-left: 0 !important;
      padding: 0 10px 60px 10px;
    }

    .lang-switch {
      display: none;
    }
  }
}

.button-menu-mobile {
  .lines {
    width: 18px;
    display: block;
    position: relative;
    height: 16px;
    transition: all .5s ease;
    margin-top: 26px;
    margin-left: 10px;
  }

  span {
    height: 2px;
    width: 100%;
    background-color: rgba($white, 0.8);
    display: block;
    margin-bottom: 5px;
    transition: transform .5s ease;

    &:nth-of-type(2) {
      width: 24px;
    }
  }

  &.disable-btn {
    display: none;
  }
}

// Light sidebar

body[data-leftbar-theme="light"] {
  .left-side-menu {
    background: $bg-leftbar-light;

    .logo {
      background: $bg-leftbar-light !important;
    }
  }

  .side-nav {
    .side-nav-link {
      color: $menu-item-light;

      &:hover,
      &:focus,
      &:active {
        color: $menu-item-light-hover;
      }
    }

    .mm-active {
      > a {
        color: $menu-item-dark-active !important;
      }
    }

    .side-nav-title {
      color: $menu-item-light;
    }
  }
}

// Dark sidenav
body[data-leftbar-theme="dark"] {
  .left-side-menu {
    background: $bg-leftbar-dark;

    .logo {
      background: $bg-leftbar-dark !important;
    }
  }

  .side-nav {

    &.cut-items-overflow {
      .side-nav-link {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
      }
    }

    .side-nav-link {
      color: $sidebar-dark-menu-item;

      &:hover,
      &:focus,
      &:active {
        color: $sidebar-dark-menu-item-hover;
      }
    }

    .mm-active {
      > a {
        color: $sidebar-dark-menu-item-active !important;
      }
    }

    .side-nav-title {
      color: $sidebar-dark-menu-item;
    }
  }
}